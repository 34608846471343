.ostrovdi__integration {
  display: flex;
  flex-direction: column;
  border-radius: 2rem; /* Rounded corners */
  box-shadow: 4px 12px 40px 6px rgba(255, 255, 255, 0.09); /* Horizontal offset, vertical offset, blur radius, spread radius, color */
}

.ostrovdi__integration-header {
  font-family: var(--font-family);
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ostrovdi__integration-header h1 {
  font-weight: var(--main-text-weight);
  font-size: var(--main-text-size);
  margin-bottom: 1.5rem;
}

.ostrovdi__integration-header h2 {
  font-weight: var(--subtext-weight);
  line-height: var(--subtext-line-height);
  font-size: var(--subtext-size);
  margin-bottom: 1.5rem;
}

.ostrovdi__integration-content {
  display: flex;
}

.ostrovdi__integration-content__image img {
  width: 100%;
  height: 100%;
}

.ostrovdi__integration-content__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 4rem;
  margin-right: 0;
  font-family: var(--font-family);
}

.ostrovdi__integration-content__text h3 {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 600;
  font-size: 38px;
}

.ostrovdi__integration-content__text p {
  margin-bottom: 3rem;
  font-weight: var(--subtext-weight);
  line-height: var(--subtext-line-height);
  font-size: var(--subtext-size);
}

@media screen and (max-width: 950px) {
  .ostrovdi__integration-content {
    flex-direction: column;
  }

  .ostrovdi__integration-content__text {
    margin-left: 0;
  }

  .ostrovdi__integration-content__text h3 {
    margin-top: 1rem;
  }

  .ostrovdi__integration-content__text p {
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 812px) {
  .ostrovdi__integration-content__text h3 {
    font-weight: 500;
    font-size: 24px;
  }
}
