* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: var(--color-bg);
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.ostrovdi__content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.ostrovdi__footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;
}

.gradient__bg {
  background: var(--gradient-background);
}

.intro__section__padding {
  padding: 2rem 6rem;
}

.section__margin {
  margin: 2rem 2rem;
}

.section__padding {
  padding: 4rem 4rem;
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@media screen and (max-width: 700px) {
  .intro__section__padding {
    padding: 2rem 4rem;
  }

  .section__margin {
    margin: 2rem 2rem;
  }

  .section__padding {
    padding: 2rem 2rem;
  }
}

@media screen and (max-width: 550px) {
  .intro__section__padding {
    padding: 2rem 3rem;
  }

  .section__margin {
    margin: 1rem;
  }

  .section__padding {
    padding: 2rem;
  }
}
