.accent-button {
  background-color: var(--color-text); /* White background */
  color: var(--color-bg); /* Black text */
  border: none; /* No border */
  padding: 15px 20px; /* Padding inside the button */
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 1em; /* Standard font size */
  border-radius: 64px; /* Fully rounded edges */
  height: 56px;
  font-family: var(--font-family);
  font-style: normal;
}

.accent-button:hover {
  /* Optional: Style for hover state */
  background-color: var(--color-subtext); /* Slightly darker background on hover */
}
