.ostrovdi__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ostrovdi__footer h1 {
    width: 100%;
    text-align: center;
    margin: 1.5rem;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: bold;
}

.ostrovdi__footer-button {
    margin-bottom: 1.5rem;
}

.ostrovdi__footer-copyright {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 2rem;
    margin-bottom: 1.5rem;
    width: 100%;
}

.ostrovdi__footer-copyright p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: lighter;
    font-size: small;
}