.ostrovdi__copyright {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
}

.ostrovdi__copyright p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: lighter;
    font-size: small;
}