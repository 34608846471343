.ostrovdi__header {
    display: flex;
    justify-content: center; /* Centers the logo horizontally */
    align-items: center; /* Centers the logo vertically */
    padding-top: 20px; /* Adds some space above and below the logo */
}

.ostrovdi__header_logo {
    height: 75px; /* Set the height of the logo */
    width: auto; /* Maintains the aspect ratio of the logo */
    cursor: pointer;
}
