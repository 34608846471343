@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

/* Root variables */
:root {
    /* Color scheme */
    --color-bg: #1a1a1a; /* Dark background */
    --color-text: #ffffff; /* White text */
    --color-subtext: #bbbbbb; /* Light grey for subtext */

    /* Gradients */
    --gradient-text: linear-gradient(to right, #ffffff, #cccccc);
    --gradient-bar: linear-gradient(to right, #4c4c4c, #1a1a1a);
    --gradient-background: linear-gradient(to bottom, #232323, #0d0d0d);

    /* Font */
    --main-font: 'Montserrat', sans-serif;

    --main-text-size: 3rem;
    --main-text-weight: 700;

    --subtext-size: 1rem;
    --subtext-weight: 500;
    --subtext-line-height: 22px;
}

body {
    margin: 0;
    font-family: var(--main-font);
    background-color: var(--color-bg);
    color: var(--color-text);
}