.ostrovdi__comingSoon {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ostrovdi__comingSoon-heading {
    margin: 2rem;
}

.ostrovdi__comingSoon-heading h1 {
    width: 100%;
    margin-top: 1.5rem;
    text-align: center;
    font-family: var(--font-family);
    font-size: 2rem;
    font-style: normal;
    font-weight: bold;
}

@media screen and (max-width: 500px) {
    .ostrovdi__comingSoon-heading h1 {
        font-size: 18px;
        font-style: normal;
        font-weight: bold;
    }
}