.ostrovdi__intro {
    display: flex;
    flex-direction: row;
}

.ostrovdi__intro-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-top: -2rem;
    margin-right: 2rem;
}

.ostrovdi__intro-content h2 {
    font-family: var(--font-family);
    font-weight: 500;
    color: var(--color-subtext);
}

.ostrovdi__intro-content h1 {
    font-family: var(--font-family);
    font-weight: var(--main-text-weight);
    font-size: var(--main-text-size);
    margin-top: 1.5rem;
}

.ostrovdi__intro-content p {
    font-family: var(--font-family);
    font-weight: var(--subtext-weight);
    margin-top: 1.5rem;
    font-size: var(--subtext-size);
}

.ostrovdi__intro-content__buttons {
    background-color: none;
    display: flex;
    flex-direction: row;
    margin-top: 1.5rem;
    margin-left: -10px;
}

.ostrovdi__intro-content__more {
    background: none;
    border: none;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: bold;
    color: var(--color-text);
    height: 56px;
    font-size: 1rem;
    cursor: pointer;
    padding: 15px 20px;
    margin-left: 10px;
}

.ostrovdi__intro-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
}

.ostrovdi__intro-image img {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 950px) {
    .ostrovdi__intro {
        flex-direction: column;
    }
    .ostrovdi__intro-content {
        margin-right: 0;
    }
}